<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
// import Button from '../components/reusable/Button.vue';
import AppFeature from '../components/shared/AppFeature.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		ProjectsGrid,
		// Button,
		AppFeature,
	},
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />
		<AppFeature class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<ProjectsGrid />

		<!-- Load Hubungi Kami button -->

		<!-- Projects grid -->
		<!-- <section class="pt-14">
			<div class="text-center">
				<p
					class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
					Tentang
				</p>
			</div>

			<div class="mt-10 sm:mt-10">
				<h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
					Sertifikat kompetensi adalah pengakuan kompetensi atas prestasi mahasiswa yang sesuai dengan keahlian dalam cabang ilmunya, atau memiliki prestasi di luar program studinya yang telah lulus ujian kompetensi.
				</h3>
	
			</div>
			
		</section> -->
	</div>
</template>

<style scoped></style>
