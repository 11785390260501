<script>
import feather from 'feather-icons';
// import ProjectsFilter from './ProjectsFilter.vue';
// import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	data: () => {
		return {
			projects,
			projectsHeading: 'Paket',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-16 sm:pt-14 ">
		<!-- Projects grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				{{ projectsHeading }}
			</p>
		</div>

		<!-- Filter and search projects -->
		<div class="mt-10 sm:mt-10">
			<h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
				Pilih paket seseuai kebutuhan
			</h3>

		</div>

		<!-- Projects grid -->
		<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
			<!-- <ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/> -->
			<router-link to="/projects/single-project"
				class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
				aria-label="Single Project">
				<div>
					<img src="@/assets/images/package.svg" alt="package" class="rounded-t-xl border-none" />
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2">
						Paket satu
					</p>
					<span class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">Detail Paket
						satu</span>
				</div>
			</router-link>

			<router-link to="/projects/single-project"
				class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
				aria-label="Single Project">
				<div>
					<img src="@/assets/images/package.svg" alt="package" class="rounded-t-xl border-none" />
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2">
						Paket dua
					</p>
					<span class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">Detail Paket
						dua</span>
				</div>
			</router-link>

			<router-link to="/projects/single-project"
				class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
				aria-label="Single Project">
				<div>
					<img src="@/assets/images/package.svg" alt="package" class="rounded-t-xl border-none" />
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2">
						Paket Tiga
					</p>
					<span class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">Detail Paket
						tiga</span>
				</div>
			</router-link>
		</div>
	</section>
</template>

<style scoped></style>
