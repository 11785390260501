<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section class="pt-10 sm:pt-14">

		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				Layanan
			</p>
		</div>

		<div class="mt-10 sm:mt-10">
			<h3 class="font-general-regular
				text-center text-secondary-dark
				dark:text-ternary-light
				text-md
				sm:text-xl
				font-normal
				mb-4
			">
				Pelanggan adalah prioritas kami
			</h3>

		</div>

		<div class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10">

			<!-- Banner left contents -->
			<div class="w-full md:w-1/2 text-left">
				<h1
					class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-center text-ternary-dark dark:text-primary-light uppercase">
					Layanan Satu
				</h1>
				<p
					class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-center leading-none text-gray-400">
					Pilihan Satu
				</p>
			</div>

			<!-- Banner right illustration -->
			<div class="w-full md:w-1/2 text-right">
				
					<img  class="w-96" src="@/assets/images/feature1.svg" alt="feature1" />
				
			</div>
		</div>

		<div class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10">

			<!-- Banner left contents -->
			<div class="w-full md:w-1/2 text-right">
					<img class="w-96"  src="@/assets/images/feature2.svg" alt="feature2" />
			</div>

			<!-- Banner right illustration -->
			<div class="w-full md:w-1/2 text-right">
				<h1
					class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-center text-ternary-dark dark:text-primary-light uppercase">
					Layanan Dua
				</h1>
				<p
					class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-center leading-none text-gray-400">
					Pilihan Dua
				</p>
			</div>
		</div>
	</section>
</template>

<style scoped></style>
